// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
export const API_URL: string = import.meta.env.VITE_API_URL;

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
export const WS_URL: string = import.meta.env.VITE_WS_URL;

export const token = localStorage.getItem('token') ?? '';

export function getClientInfo(): string {
  // Get browser
  const browserMatch =
    navigator.userAgent.match(/(Chrome|Safari|Firefox|Edge)\/[\d.]+/)?.[1] ||
    'Unknown';

  const osPatterns = {
    Windows: 'Windows',
    Mac: 'Mac',
    Linux: 'Linux',
    Android: 'Android',
    iPhone: 'iOS',
    iPad: 'iOS',
    iPod: 'iOS',
  };

  const ua = navigator.userAgent;
  const os =
    Object.entries(osPatterns).find(([key]) => ua.includes(key))?.[1] ||
    'Unknown';

  return `${browserMatch}/${os}`;
}
